.Header {
    color: #0c7968;
    font-size: 3em;
    font-weight: 200;
    padding-top: 0px;
    border-bottom: 2px solid #055f51;
}

.Header h1{
    font-size: 1em;
    font-weight: 200;
    color: #0c7968;
    margin: 0;
    padding-bottom: 10px;
}

.MainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80%;
    width: 100%;
    flex: 1 0 auto;
    background-color: #fafafa;
}

.ContentContainer {
    margin: 0 auto;
    width: 95%;
    height: 100%;
    overflow-x: hidden;
}

#HeroBar {
    background-color: #2c3e50;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 80%;
    width: 100%;
    flex: 1 0 auto;
    font-size: 12px;
}

#HeroFlexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: flex-start;
    align-items: center;
}

#HeroContent {
    font-size: 3.6em;
    font-weight: 200;
    min-width: 680px;
    max-width: 680px;
    margin: 0 20px;
}
    #HeroContent h1 {
        font-size: 1em;
        font-weight: 200;
    }

    #HeroContent a {
        text-decoration: none;
        color: #fff;
    }

:-webkit-any(article,aside,nav,section) h1 {
    font-size: 1.5em;
    margin-block-start: 0.60em;
    margin-block-end: 0.60em;
}

#HeroDisclaimerContainer {
    margin: 0 5%;
}

#HeroDisclaimer {
    padding-bottom: 5px;
}

#HeroContact {
    padding-top: 0;
    padding-bottom: 10px;
}

.HomePageButton {
    background-color: #0e1d2b;
    border: 1px solid #0e1d2b;
    padding: 10px 50px 8px 50px;
    font-size: medium;
    color: #fff;
}

body {
    font-family: 'Segoe UI';
    font-size: 13px;
}

.wrapper, html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}
