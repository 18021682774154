.Header {
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
}

.Header h1 {
    font-size: .85em;
    font-weight: 200;
    color: #26639c;
    margin: 0;
    padding-bottom: 10px;
    font-family: 'Segoe UI';
}

.newproject-section h2 {
    display: block;
    font-size: 1.2em;
    margin-block-start: 2em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.productSelectForm {
    padding-top: 20px;
    float: left;
    width: 30%;
    min-width: 200px;
}

body {
    font-family: 'Segoe UI';
    font-size: 12px;
}

.modernSelect {
    width: 100%;
    padding: 5px;
    font-size: 1.2em;
    height: 34px;
}

.productSelectFormLabel {
    display: block;
}

#PPPButton1 {
    margin-top: 20px;
    margin-right: 20px;
    background: #26639c;
    border: 2px solid #26639c;
}

#PPPButton2 {
    margin-top: 20px;
    margin-right: 20px;
    background: #26639c;
    border: 2px solid #26639c;
}

#NavigationContainer {
    float: left;
    clear: both;
}


#alert-dialog-title {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    padding-left: 0px;
    padding-top: 0;
}

.MuiTypography-h6 {
    display: flex;
    flex-direction: row;
    align-items:center;
}

