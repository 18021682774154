#alert-dialog-description-main {
    font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
    font-weight: 400;
    border: 2px solid #ffff;
}
#alert-dialog-title-main .MuiTypography-h6 {
    font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans- serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.4em;
    font-weight: 490;
    border: 2px solid #ffff;

}
#alert-dialog-description-action {
    border: 2px solid #ffff;
}
.warningicon {
    height: 1.5em;
    width: 1.5em;
    /* background: #ffd11b; */
    vertical-align: bottom;
    margin-right: 0.4em;
}