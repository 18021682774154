

.division {
    padding-bottom: 50px;
    min-height:35em;
}
#mainoptions{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.uploadText {
    padding-bottom: 0.9em;
}

#MenuList{
    font-weight:bold;
    display:flex;
    flex-direction:column;
}
#MenuCard {
    display: flex;
    flex-direction: column;
    margin: 1%;
    min-width: 21%;
    min-height: 35em;
    max-height: 35em;
    outline: 2px solid transparent;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
#CheckBoxes {
    margin-left: 20px;
}

#PrimaryListStyle .MuiListItemText-root .MuiTypography-body1 {
    font-size: 1.3rem;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 580;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}

#SecondaryListStyle .MuiListItemText-root .MuiTypography-body1 {
    font-size: 1.05rem;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 350;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}

.MuiTableRow-root .MuiTableCell-root {
    display: table-cell;
    padding: 16px;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.MuiFormControl-root {
    border: 0;
    margin: 20px;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
#name {
    margin: 1%;
}
#ListStyle {
    font-weight: bold;
}

#statusRepresentationAlert {
    min-width: 0.9em;
    height: 3em;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
}
#DialogBox {
    min-height: 30%;
    min-width: 30%;
    border: 2px solid #ffff;
}
#alert-dialog-title {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    padding: 0;
    border-bottom: 1px solid rgb(95 93 93 / 30%);
}
#CheckBox {
    color : #26639c;
}
#DisplayCard {
    display: flex;
    flex-direction: column;
    margin: 2%;
    margin-top: 0%;
    margin-bottom: 1%;
    width: 90%;
    min-height: 100%;
    max-height: 48em;
    justify-content: space-between;
    align-items: flex-end;
}
#ButtonStyle {
    margin-right: 2%;
    margin-bottom: 1%;
    background-color: #26639c;
    color: white;
    border: 2px solid #26639c;
    outline: 2px solid transparent;
}

.border {
    outline: 1.5px solid transparent;
}

#cards {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    margin-top: 1em;
}
#contentProp-adminSettings {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    justify-content: space-between;
}

#MiddleBox
{
    min-height:30em;
}

.btn {
    margin-top: 20px;
    border: 2px;
    background-color: white;
    padding-top: 10px;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-color: #2196F3;
    color: dodgerblue;
}

    .btn:hover {
        background: #2196F3;
        color: white;
    }

.Header {
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
    margin-bottom: 0.4em;
}


#TableHeader-adminSettings {
    background-color: #efefef;
    text-transform: uppercase;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 1em;
}


h2 {
    display: block;
    font-size: 1.2em;
    margin-block-start: 1em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.0em;
    margin-block-start: 1em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#MainNavigationContainer {
    display: flex;
    flex-direction: row;
    float: right;
    width: 100%;
    justify-content: flex-end;
    margin-right: 4em;
    margin-top: 2em;
    margin-bottom: 3em;
    align-self: end;
}

#container
{
    max-height:47em;
    min-height:25em;
}

#SubHeading {
    font-size: 0.5em;
}
#CardHeader {
    background-color: #26639c;
}

#sideblock-adminSettings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24%;
    outline: 2px solid transparent;
}

#productProfileBody {
    font-size: 1em;
    border: 1px solid #f3f3f3;
    vertical-align: top;
    padding: .7em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
@media screen and (-ms-high-contrast: active) {
    /* All high contrast styling rules */
}
.cards .MuiButton-root:not(:disabled) {
    background: #2960db;
    border: 2px solid #2960db;
    outline: 2px solid transparent;
}
.borderAlert {
    border: 2px solid #ffff;
}
#DisplayCard .MuiTablePagination-root {
    color: rgba(0, 0, 0, 0.87);
    overflow: unset;
    font-size: 1em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}
