.footer-links {
    margin-left: 20px;
    line-height: 30px;
}

.footer-link {
    display: inline-block;
    margin-right: 30px;
}


.mslogo {
    margin-right: 20px;
}


#PageFooter {
    flex: none;
    padding-top: 10px;
    height: 50px;
    width: 100%;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    bottom: 0;
    position: sticky;
}