.bottomBreak {
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 5px;
    border-bottom: 2px solid #055f51;
}

.textboxProperties {
    width: 100%;
}

.cardProperties {
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.tableProperties {
    padding-top: 3px;
    padding-bottom: 3px;
}

.updatePMCT {
    padding-top: 25px;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 10px;
    padding-left: 10px;
}

.keyRange {
    padding-top: 15px;
}

.cardShadow {
    box-shadow: -0.75rem 1.25rem 1.75rem rgba(0, 0, 0, 0.05);
}

.form__input--rounded, .hs-form .hs-input--rounded, .hs-form__input--rounded {
    border-radius: 28px;
    height: 50px;
}

.labelFont {
    font-family: "Poppins", "Arial", "Helvetica Neue", sans-serif;
    font-size: 17px;
    color: #555;
    text-transform: capitalize;
    display: block;
    margin-bottom: 5px;
}

.linearGradient {
    background: linear-gradient(to bottom right, #2cfccc 0%, #407679 100%);
    margin-right: 8px;
}

.topMargin {
    margin-top: 15px;
}

.rangeCard {
    border: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.cardMarginBottom {
    margin-bottom:16px;
}

.sideBarLabel {
    margin-top: 8pc;
    font-size: 30px;
    color: white;
    font-family: inherit;
    text-shadow: crimson;
    font-variant: all-petite-caps;
    font-weight: 300;
    mix-blend-mode: color;
}

.sideBarLabelMain {
    font-size: 30px;
    color: white;
    font-family: inherit;
    text-shadow: crimson;
    font-variant: all-petite-caps;
    font-weight: 300;
}

.deleteButton {
    margin-bottom: 20px;
    border: 2px solid #26639c;
}

.pageProp {
    margin: 10px;
    padding: 10px;
}