#cardContent {
    display: flex;
    box-shadow: 0 0 2px 0 rgba(57,63,72,.3);
    justify-content: space-around;
}
.Header {
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
}

.uploadWidth {
    width: 100%;
}

#contrast {
    border: 2px solid #ffff;
}
.Header h1 {
        font-size: .85em;
        font-weight: 200;
        color: #26639c;
        margin: 0;
        padding-bottom: 10px;
        font-family: 'Segoe UI';
}

.container {
    max-height: 440px;
        display: flex; 
        justify-content: space-evenly,;
        min-width:unset;
}
#customized-dialog-title {
    padding: 0px;
    border: 2px solid #ffff;
}

#customized-dialog-footer {
    padding: 0px;
    border: 2px solid #ffff;
}
#customized-dialog-content {
    padding: 0px;
    border: 2px solid #ffff;
}
.MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}
#SearchBarStyle {
    border-radius: 15px;
    background-color: white;
    color: #26639c;
    border: 2px solid #26639c;
    font-size: 0.5em;
    margin-bottom: 15px;
    padding: 5px;
    margin-right: 3px;
}
#cardBody {
    font-size: 0.62em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 3.5em;
}
#SearchBar {
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items:center;
}
#CheckBox {
    align-items: flex-end;
    justify-content: flex-end;
    color: #097309;
}
#GroupAll
{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-items:baseline
}
#AlertDialog
{
    min-height:25%;
    min-width:40em;
}
#OK {
    color: #097309;
    height: 1.5em;
    width: 1.5em;
}
#InPlanning {
    color: #6daabb;
    height: 1.5em;
    width: 1.5em;
}
#Processing {
    color: #f9d513;
    height: 1.5em;
    width: 1.5em;
}
#Fail {
    color: #a90020;
    height: 1.5em;
    width: 1.5em;
}
#statusRepresentation {
    min-width: 1.2em;
    height:6.3em;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
    
}
.activityButton {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 1em;
}
#searchIcon {
    width: 48px;
    height: 48px;
    color: white;
    background-color: #26639c;
    border: 1px solid #26639c;
    margin-bottom: 1em;
}
#searchBar {
    display: flex;
    flex-direction: row;
}
#cardFooter {
    font-size: 1em;
    display: flex;
    flex-direction: row;
    float: right;
}
#SubHeading {
    font-size: 0.5em;
}
#LegendBody {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#SubHeaderRow
{
    display:flex;
    justify-content:space-between;
}
#Legend{
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    margin:10px;
}
#colorcode{
   margin-right:2em;
    display:flex;
    flex-direction:row;
}
#footerContainer {
    padding-bottom: 4em;
    width: 100%,
}

#contentValues {
    font-weight: 640;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}

#contentHeader {
    font-weight: 400;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}

#avatar {
    display: flex;
    flex-direction: row;
    margin-left:0px;
    padding-left:0px;
}

#headerLayout {
    display: flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    border: 2px solid #ffff;
}

#popper {
    position: absolute;
    transform: translate3d(939px, 192px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    z-index: 999;
}

#statusRepresentation {
    min-width: 1.1em;
    height: 6em;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
}
.buttonStyle {
    color: white;
    background-color: #26639c;
    margin-top: 1em;
    border: 1px solid #26639c;
}

#buttonStyle2 {
    color: white;
    background-color: #26639c;
    margin-top: 1em;
    border: 1px solid #26639c;
}
#ButtonAlign
{
    display:flex;
    
    justify-content:center;
}
.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    /* padding: 16px 24px; */
}
.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
}

.Header h1 {
    font-size: .85em;
    font-weight: 200;
    color: #26639c;
    margin: 0;
    padding-bottom: 10px;
    font-family: 'Segoe UI';
}
#buttonAlign {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;


}
.MuiDialog-paperWidthSm {
    
    max-width: fit-content;
    min-width: fit-content;
    max-height:45%;
}
#cardActionBar {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: 8px;
    margin-right: -8px;
}

#ActivityStatusTableBody {
    font-size: 1em;
    vertical-align: top;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 5px;
    max-width: calc(20vw);
    height: 2em;
    border: 1px solid #f3f3f3;
}

#statusRepresentationAlert {
    min-width: 0.9em;
    height: 3em;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
}
.MuiSnackbarContent-message {
    font-size: 1.4em;
}

#content {
    position: relative;
    height: 3%;
    width: 3%;
    right: 0;
}

    #content.on {
        -webkit-animation-name: in-out;
        animation-name: in-out;
        -webkit-animation-duration: 0.7s;
        animation-duration: 0.7s;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-iteration-count: 1;
        animation-iteration-count: 1;
    }

.inputSearchBar {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 2px solid #26639c;
    border-radius: 50%;
    background: none;
    color: black;
    font-size: 0;
    font-weight: 400;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    outline: 0;
    -webkit-transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
    transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out, padding 0.2s;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}

.search {
    background: none;
    position: absolute;
    top: 0px;
    left: 0;
    height: 50px;
    width: 50px;
    padding: 0;
    border-radius: 100%;
    outline: 0;
    border: 0;
    color: inherit;
    cursor: pointer;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}

    .search:before {
        content: "";
        position: absolute;
        width: 18px;
        height: 2px;
        background-color: #26639c;
        border: 1px solid #26639c;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 47px;
        margin-left: 15px;
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
    }

.close {
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    -webkit-transition-delay: 0.4s;
    transition-delay: 0.4s;
    color: #fff;
}

    .close:before {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        margin-top: 13px;
        margin-left: -13px;
        background-color: #26639c;
        border: 1px solid #26639c;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
    }

    .close:after {
        content: "";
        position: absolute;
        width: 27px;
        height: 4px;
        background-color: #26639c;
        border: 1px solid #26639c;
        margin-top: 13px;
        margin-left: -13px;
        cursor: pointer;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

.square {
    box-sizing: border-box;
    padding: 0 40px 0 10px;
    width: 300px;
    height: 50px;
    border: 2px solid #26639c;
    border-radius: 0;
    background: none;
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 400;
    outline: 0;
    -webkit-transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s;
    transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out, padding 0.2s;
    -webkit-transition-delay: 0.4s, 0s, 0.4s;
    transition-delay: 0.4s, 0s, 0.4s;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
}
button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

#Released {
    color: #097309;
    height: 1.5em;
    width: 1.5em;
}

#InPlanning {
    color: #6daabb;
    height: 1.5em;
    width: 1.5em;
}

#InProgress {
    color: #f9d513;
    height: 1.5em;
    width: 1.5em;
}

#Error {
    color: #a90020;
    height: 1.5em;
    width: 1.5em;
}
#LegendBody {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system","BlinkMacSystemFont","Roboto","Helvetica Neue","Helvetica","Ubuntu","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}
#Legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 10px;
}

#colorcode {
    margin-right: 2em;
    display: flex;
    flex-direction: row;
}

#PPPButtonRefresh {
    margin-top: 20px;
    margin-right: 20px;
    background-color: #26639c;
    border: 1px solid #26639c;
    color: white;
}

#PPPButtonGroup {
    margin-top: 20px;
    margin-right: 20px;
    background-color: #26639c;
    border: 1px solid #26639c;
    color: white;
}

#PPPButtonPing {
    margin-top: 20px;
    margin-right: 20px;
    border: 1px solid #26639c;
    background-color: #26639c;
    color: white;
}