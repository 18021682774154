.Header {
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
}

    .Header h1 {
        font-size: .85em;
        font-weight: 200;
        color: #26639c;
        margin: 0;
        padding-bottom: 10px;
        font-family: 'Segoe UI';
    }
.groupIdRanges {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: inherit;
 
    margin: 2em;
    float: left;
    width: 100%;
}
#displayBoxes {
    width: 30%;
    margin-right: 4em;
    margin-left: 4em;
}
h2 {
    display: block;
    font-size: 1.2em;
    margin-block-start: 2em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#groupIdTable: {
    maxHeight: 440;
    display: flex;
    overflow-x: visible;
    width: 80%;
}
.productSelectForm {
    padding-top: 20px;
    float: left;
    width: 30%;
    min-width: 200px;
}
#pkpnTable
{
    justifyContent: center;
    margin: 2em;
    width:90%;
}
#groupIDTable {
    width: 80%;
    /* justify-self: right; */
    align-self: center;
    margin: 2em;
}
body {
    font-family: 'Segoe UI';
    font-size: 12px;
}

#productName{
    width:100%;
}
#statusRepresentation {
    min-width: 1.1em;
    height: 6em;
    display: flex;
    flex-direction: row;
    margin-right: 1em;
}
#LowerBox
{
    display:flex;
    flex-direction:column;
}
#TableSize{
    width:55%;
}
.modernSelect {
    width: 100%;
    padding: 5px;
    font-size: 1.2em;
    height: 34px;
}

.productSelectFormLabel {
    display: block;
    padding-bottom: 10px;
    padding-top: 20px;
}

#PPPButton {
    margin-top: 20px;
    margin-right: 20px;
    background-color: #26639c;
    color: white;
    border: 2px solid #26639c;
}
#PPPButtonAdd {
    margin-left: 2em;
    margin-top: 20px;
    margin-right: 20px;
    background-color: #26639c;
    color: white;
    border: 2px solid #26639c;
}

#NavigationContainer {
    float: left;
    clear: both;
}

#SelectedRangeBox {
    margin-left: 6em;
    width: 80%;
    float: left;
    display:flex;
    flex-direction:column;
}

.selectRangeBox {
    width: 55%;
    float:left;
}
#PKPNDetails{
    min-width:55%;
}
.rangeBox {
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    padding-bottom:3%;
    margin-bottom:1%;
    justify-content:space-around;
    border-bottom: 2px solid #3766c3;
}
#ShowDetailsButton {
    justify-content: space-between;
    color: #26639c;
    text-transform: none;

}

#ShowDetailsButton2 {
    justify-content: space-between;
    color: #26639c;
    text-transform: none;
}
#AutoComplete
{
    width:200%;
    min-width:110%;
}
.secondrangeBox {
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    padding-bottom: 15px;
    margin-bottom: 15px;
   
}

#selectedRange {
    height: 9em;
    width: 20em;
}

#availableRanges {
    height: 15em;
    width:9em;
    margin-left:5em;
}


#TableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-weight: bold;
    border-bottom: 2px solid #3766c3;
}
#AvailableTable {
    justifyContent: center;
    margin: 2em;
    width: 70%;
}
#TableAvaialbleHeader {
    background-color: ghostwhite;
    text-transform: uppercase;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-weight: bold;
    border-bottom: 2px solid #3766c3;
}

#PKPNTableBody {
    font-size: 1em;
    vertical-align: top;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 5px;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
}
#GroupIDTableBody {
    font-size: 1em;
    vertical-align: top;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 5px;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
}
#PKPNValue
{
    width:45%;
}
#SetPKPN
{
    display:flex;
    flex-direction:row;
    justify-content:space-around;

}
#Cards{
    display:flex;
    flex-direction:column;
    max-height:70%;
    min-height:70%;
    
}
#Actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
#Cardmargin {
    margin-left:2em;
    margin-right:2em;
    margin-top:2em;
}
#CardHeader {
    background-color: #26639c;
}
#ForwardIcon{
    margin-top:3.2em;
}
#SubHeading{
    font-size:0.5em;
}
#paddingBottom
{
    padding-bottom:0px;
}
#MainHolder
{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    min-height:50em;
}
#SetValues{
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    
}
.navigationButton {
    background-color: #3372d5;
    border: 1px solid #3372d5;
    color: #fff;
    padding: 10px 25px;
    margin-top: 10px;
}

#MainNavigationContainer {
    display: flex;
    flex-direction: row;
    float: right;
    width: 80%;
    justify-content: flex-end;
    margin-right: 4em;
    margin-top: 2em;
}

#DialogBox {
    min-height: 30%;
    min-width: 60%;
   
}

.MuiTableContainer-root {
    overflow-x: visible;
}

.reallocate-backdrop {
    z-index: 'theme.zIndex.drawer + 1';
    color: '#fff';
}