

.uploadExcel {
    margin-top: 2%;
    margin-bottom: 10%;
    text-align: center;
    color: rgba(0,0,0,.55);
    color: var(--text-secondary-color,rgba(0, 0, 0, .55));
    overflow: hidden;
}

#attachFileIcon {
    font-size: 6em;
}

.division {
    padding-bottom: 50px;
    min-height:35em;
}

.uploadText {
    padding-bottom: 0.9em;
}

#contentProp-uploadPKSpec {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 80%;
    justify-content: space-between;
}

#MiddleBox
{
    min-height:30em;
}

.btn {
    margin-top: 20px;
    border: 2px;
    background-color: white;
    padding-top: 10px;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    border-color: #2196F3;
    color: dodgerblue;
}

    .btn:hover {
        background: #2196F3;
        color: white;
    }

.Header {
    color: #26639c;
    font-size: 3em;
    font-weight: 200;
    border-bottom: 2px solid #26639c;
    margin-bottom: 0.4em;
}

h2 {
    display: block;
    font-size: 1.2em;
    margin-block-start: 1.5em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#TableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-weight: bold;
    border-bottom: 2px solid #3766c3;
}

#PKSpecsTableBody {
    font-size: 1em;
    vertical-align: top;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 5px;
    max-width: calc(20vw);
    height: 25em;
    border: 1px solid #f3f3f3;
}

h2 {
    display: block;
    font-size: 1.2em;
    margin-block-start: 1em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h3 {
    display: block;
    font-size: 1.0em;
    margin-block-start: 1em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#ButtonContainer {
    display: flex;
    flex-direction: row;
    float: right;
    width: 100%;
    justify-content: flex-end;
    margin-right: 4em;
    margin-top: 2em;
    align-self: end;
}
#SubHeading {
    font-size: 0.5em;
}