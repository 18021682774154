#contentProp{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

#navBarAction2:focus-visible {
    border: 2px solid white;
}

#navBarAction3:focus-visible {
    border: 2px solid white;
}

#navBarAction5:focus-visible {
    border: 2px solid white;
}

#navBarAction2:hover {
    border: 2px solid white;
}

#navBarAction3:hover {
    border: 2px solid white;
}

#navBarAction5:hover {
    border: 2px solid white;
}

.MuiButtonBase-root .MuiListItemText-root .MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: white;
}

:root {
    --color-background: #26639c;
}

@media (prefers-color-scheme: dark) {
    :root {
        --color-background: #ffff;
    }
}


#IconContrastLeft {
    fill: var(--color-background);
}
#IconContrastRight {
    fill: var(--color-background);
}
#IconContrastUser {
    fill: var(--color-background);
}

#IconContrastHistory {
    fill: var(--color-background);
}

#IconContrastLaunch {
    fill: var(--color-background);
}