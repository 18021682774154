
html {
  font-size: 14px;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.headerPID {
    color: #26639c;
    font-weight: 400;
}

.headerPlanningPipeline {
    color: #525252;
    font-weight: 200;
    margin-left: -4px;
}

.menuContent {
    font-size: 3.6em;
    font-weight: 200;
    padding-left: 50px;
}

.loggedUser {
    color: #525252;
    padding-top: 20px;
    padding-right: 50px;
    font-size:16px;
    flex-direction:row;
    justify-content:flex-end;
    display:flex;
}

#HeaderContainer {
    display: inherit;
    position: sticky;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: #f0f0f0;
    flex: none;
    -webkit-box-shadow: 0 0 5px 0 rgba(57,63,72,.3);
    -moz-box-shadow: 0 0 5px 0 rgba(57,63,72,.3);
    box-shadow: 0 0 5px 0 rgba(57,63,72,.3);
}

#MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #f0f0f0;
    position: sticky;
    justify-content: space-between;
    flex-direction: row;
}
